<template>
  <div class="news-edit" v-if="!isProcessing">
    <news-form />
  </div>
</template>

<script>
import NewsForm from '@/components/news/form.vue'

export default {
  name: 'news-edit',
  components: { NewsForm },
  async mounted () {
    if (!this.article) await this.$store.dispatch('articles/getArticle', this.aid)

    // ニュース情報が存在しない場合はnotfoundへ遷移
    if (!this.article) {
      this.$router.push({ name: 'notfound' })
      return 0
    }

    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ニュースのID
     */
    aid () {
      return this.$route.params.aid
    },
    /**
     * @return {Object} ニュース情報
     */
    article () {
      return this.$store.getters['articles/article'](this.aid)
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.news-edit {
  padding: $padding_height $padding_width;
  margin-top: $header_height;
}
</style>
